// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-culture-js": () => import("./../../../src/templates/culture.js" /* webpackChunkName: "component---src-templates-culture-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-preview-article-js": () => import("./../../../src/templates/preview/article.js" /* webpackChunkName: "component---src-templates-preview-article-js" */),
  "component---src-templates-preview-workdetail-js": () => import("./../../../src/templates/preview/workdetail.js" /* webpackChunkName: "component---src-templates-preview-workdetail-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-workdetail-js": () => import("./../../../src/templates/workdetail.js" /* webpackChunkName: "component---src-templates-workdetail-js" */)
}

